import React from 'react'
import './webtradermeta5.scss';
// import Wanttoget from '../home/wanttoget';
import Gotonext from '../home/gotonext';

export default function Webtradermeta5() {
  return (
    <>
          <Gotonext />
          {/* <Wanttoget /> */}
    </>
  )
}
