import React from 'react'
import "./contactus.scss";
import Getintouch from './getintouch';
import Gotonext from '../home/gotonext';
// import Wanttoget from '../home/wanttoget';

export default function Contcatus() {
    return (
        <>
            <Getintouch />
            <Gotonext />
            {/* <Wanttoget /> */}
        </>
    )
}
